/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  // Button,
  Card,
  CardTitle,
  CardText,
  // NavItem,
  // NavLink,
  // Nav,
  Table,
  // TabContent,
  // TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import NavbarHome from "components/Navbars/NavbarHome.js";
// import Header from "components/Headers/Header.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Footer from "components/Footers/Footer.js";
import ContactModal from "components/ContactModal.js";

function Courses() {
  // const [activeTab, setActiveTab] = React.useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <NavbarHome />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          {/* <div className="owner">
            <div className="avatar">
              <img
                alt="hand writing"
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/witing-hand.jpg")}
              />
            </div>
            <div className="name">
              <h4 className="title">
                Courses & Coaching
                <br />
              </h4>
            </div>
          </div>
          <br /> */}
          {/* <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Upcoming
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Past
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div> */}
          {/* Tab panes */}
          {/* <TabContent className="following" activeTab={activeTab}> */}
          {/* <TabContent className="following">
            <TabPane tabId="1" id="follows"> */}
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <br />
              <h2 className="text-center m-4">Upcoming Courses</h2>
              <Card className="card-register align-items-center" id="essay">
                <CardTitle>Write A Powerful Personal Essay</CardTitle>
                <br />
                <CardText>
                  <br />
                  <br />
                  <h5 className="description text-center">
                    Learn how to write about transformational experiences in a
                    way that makes your reader sit up and pay attention. In this
                    class, we’ll go from brainstorm to draft to edits with an
                    eye for what makes an essay compelling. To help guide us,
                    we'll read and discuss examples from contemporary authors
                    and talk about the big questions in storytelling (like "is
                    it okay to make some things up if I don't remember
                    everything?").
                  </h5>
                  <br />
                  <Table borderless>
                    <tbody>
                      <tr>
                        <th scope="row">Dates:</th>
                        <td>September 1, 8, 15, 22 (Tuesday meetings)</td>
                      </tr>
                      <tr>
                        <th scope="row">Number of course sessions:</th>
                        <td>4</td>
                      </tr>
                      <tr>
                        <th scope="row">Length of each session:</th>
                        <td>90 minutes</td>
                      </tr>
                      <tr>
                        <th scope="row">Rate:</th>
                        <td>$300 per student (min 3 students, max 6)</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardText>
                <ContactModal
                  topicLabel="Inquiry - Write A Powerful Personal Essay"
                  buttonLabel="Inquiry / Sign Up"
                ></ContactModal>
              </Card>
              <br />
              <Card className="card-register align-items-center" id="creative">
                <CardTitle>Creative Writer Workshop</CardTitle>
                <br />
                <CardText>
                  <br />
                  <h5 className="description text-center">
                    Get inspired, write and share with a supportive community
                    across genres: poetry, fiction, and memoir. In this course,
                    we’ll explore works in each genre, create and develop our
                    own piece of writing, and engage in guided workshop and
                    feedback sessions with fellow writers. If you’re looking to
                    take your creative writing to the next level, this class is
                    for you.
                  </h5>
                  <br />
                  <Table borderless>
                    <tbody>
                      <tr>
                        <th scope="row">Dates</th>
                        <td>September 3, 10, 17, 24 (Thursday meetings)</td>
                      </tr>
                      <tr>
                        <th scope="row">Number of course sessions</th>
                        <td>4</td>
                      </tr>
                      <tr>
                        <th scope="row">Length of each session:</th>
                        <td>90 minutes</td>
                      </tr>
                      <tr>
                        <th scope="row">Rate</th>
                        <td>$300 per student (min 3 students, max 6)</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardText>
                <ContactModal
                  topicLabel="Inquiry - Creative Writer Workshop"
                  buttonLabel="Inquiry / Sign Up"
                ></ContactModal>
              </Card>
              <br />
              <Card className="card-register align-items-center" id="statement">
                <CardTitle className="text-center">
                  Weekend Workshop: Write a Powerful Personal Statement
                </CardTitle>
                <br />
                <CardText>
                  <br />
                  <h5 className="description text-center">
                    Want to make an admissions committee sit up and pay
                    attention? In this weekend course, you’ll get guided,
                    step-by-step instruction to writing a compelling personal
                    statement--one that truly showcases you. Using our
                    tried-and-true brainstorming and drafting methods, we’ll
                    take you from idea to outline to essay in just two days,
                    with plenty of opportunity for individual feedback from the
                    course instructor.
                  </h5>
                  <br />
                  <Table borderless>
                    <tbody>
                      <tr>
                        <th scope="row">Dates</th>
                        <td>September 19-20 and October 3-4</td>
                      </tr>
                      <tr>
                        <th scope="row">Number of course sessions</th>
                        <td>4</td>
                      </tr>
                      <tr>
                        <th scope="row">Length of each session:</th>
                        <td>90 minutes</td>
                      </tr>
                      <tr>
                        <th scope="row">Rate</th>
                        <td>$300 per student (min 3 students, max 6)</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardText>
                <ContactModal
                  topicLabel="Inquiry - Weekend Workshop: Write a Powerful Personal Statement"
                  buttonLabel="Inquiry / Sign Up"
                ></ContactModal>
              </Card>
              <br />
              <Card className="card-register align-items-center" id="internet">
                <CardTitle className="text-center">
                  Write for the Internet and Get Paid to Do It
                </CardTitle>
                <br />
                <CardText>
                  <br />
                  <h5 className="description text-center">
                    Love music? Cooking? Sports? Photography? TikTok dance
                    challenges? Whatever your passion is, you can write about it
                    online and get paid to do it. In this course, you'll
                    research story angles and digital publications (and what
                    they pay), learn how to develop your "beat" and online
                    persona, and, ultimately, craft a story pitch for a digital
                    publication.
                  </h5>
                  <br />
                  <Table borderless>
                    <tbody>
                      <tr>
                        <th scope="row">Dates</th>
                        <td>October 6, 13, 20, 27</td>
                      </tr>
                      <tr>
                        <th scope="row">Number of course sessions</th>
                        <td>4</td>
                      </tr>
                      <tr>
                        <th scope="row">Length of each session:</th>
                        <td>90 minutes</td>
                      </tr>
                      <tr>
                        <th scope="row">Rate</th>
                        <td>$300 per student (min 3 students, max 6)</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardText>
                <ContactModal
                  topicLabel="Inquiry - Write for the Internet and Get Paid to Do It"
                  buttonLabel="Inquiry / Sign Up"
                ></ContactModal>
              </Card>
              <br />
              <Card className="card-register align-items-center" id="internet">
                <CardTitle className="text-center">
                  Individual Coaching & Support
                </CardTitle>
                <br />
                <CardText>
                  <br />
                  <h5 className="description text-center">
                    Working on a blog, a story, or an essay? Or are you looking
                    for some academic and creative writing support? Whatever
                    your writing needs, we’re here to help. Our instructors are
                    available for 1 hour coaching and support sessions with
                    flexible scheduling.
                  </h5>
                  <br />
                  <Table borderless>
                    <tbody>
                      <tr>
                        <th scope="row">Dates</th>
                        <td>Ongoing - inquire for availability </td>
                      </tr>
                      <tr>
                        <th scope="row">Rate</th>
                        <td>
                          $75 per hour with discounts available for purchases of
                          multiple sessions
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardText>
                <ContactModal
                  topicLabel="Coaching Inquiry"
                  buttonLabel="Inquiry / Sign Up"
                ></ContactModal>
              </Card>
            </Col>
          </Row>
          {/* </TabPane>
            <TabPane tabId="2" id="past">
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <h3 className="text-center">Past Courses</h3>
                  <hr />
                  <br />
                  <Card className="card-register align-items-center">
                    <CardTitle>Write A Powerful Personal Essay</CardTitle>
                    <br />
                    <CardText>
                      <br />
                      <br />
                      <h5 className="description text-center">
                        Learn how to share ideas and experiences in a way that
                        grabs your reader and doesn’t let go.{" "}
                      </h5>
                      <br />
                      <Table borderless>
                        <tbody>
                          <tr>
                            <th scope="row">Dates:</th>
                            <td>August 3rd - 7th, 10am - 12pm PDT</td>
                          </tr>
                          <tr>
                            <th scope="row">Number of course sessions:</th>
                            <td>4</td>
                          </tr>
                          <tr>
                            <th scope="row">Length of each session:</th>
                            <td>90 minutes</td>
                          </tr>
                          <tr>
                            <th scope="row">Rate:</th>
                            <td>$300 per student (min 3 students, max 6)</td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardText>
                  </Card>
                  <br />
                  <Card className="card-register align-items-center">
                    <CardTitle>Creative Writer Workshop</CardTitle>
                    <br />
                    <CardText>
                      <br />
                      <h5 className="description text-center">
                        Get inspired, write and share with a supportive
                        community across genres: poetry, fiction, and memoir.{" "}
                      </h5>
                      <br />
                      <Table borderless>
                        <tbody>
                          <tr>
                            <th scope="row">Dates</th>
                            <td>August 10th - 14th, 10am - 12pm PDT</td>
                          </tr>
                          <tr>
                            <th scope="row">Number of course sessions</th>
                            <td>5</td>
                          </tr>
                          <tr>
                            <th scope="row">Length of each session:</th>
                            <td>90 minutes</td>
                          </tr>
                          <tr>
                            <th scope="row">Rate</th>
                            <td>$300 per student (min 3 students, max 6)</td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardText>
                  </Card>
                  <br />
                  <Card className="card-register align-items-center">
                    <CardTitle className="text-center">
                      Write a Powerful Personal Statement (Weekend Workshop)
                    </CardTitle>
                    <br />
                    <CardText>
                      <br />
                      <h5 className="description text-center">
                        From brainstorm to edits, you’l{" "}
                      </h5>
                      <br />
                      <Table borderless>
                        <tbody>
                          <tr>
                            <th scope="row">Dates</th>
                            <td>September 19th - 20th, 10am - 12pm PDT</td>
                          </tr>
                          <tr>
                            <th scope="row">Number of course sessions</th>
                            <td>5</td>
                          </tr>
                          <tr>
                            <th scope="row">Length of each session:</th>
                            <td>90 minutes</td>
                          </tr>
                          <tr>
                            <th scope="row">Rate</th>
                            <td>$300 per student (min 3 students, max 6)</td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardText>
                  </Card>
                  <br />
                  <Card className="card-register align-items-center">
                    <CardTitle className="text-center">
                      Summer 2020: Writing for the Internet
                    </CardTitle>
                    <br />
                    <CardText>
                      <br />
                      <h5 className="description text-center">------ </h5>
                      <br />
                      <Table borderless>
                        <tbody>
                          <tr>
                            <th scope="row">Dates</th>
                            <td>September 19th - 20th</td>
                          </tr>

                          <tr>
                            <th scope="row">Instructor</th>
                            <td>Katie Hunter</td>
                          </tr>
                          <tr>
                            <th scope="row">Total Seats</th>
                            <td>6</td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardText>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent> */}
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Courses;
