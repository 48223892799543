/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import NavbarHome from "components/Navbars/NavbarHome.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footers/Footer.js";
import ContactForm from "components/ContactForm.js";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <NavbarHome />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Card className="card-plain">
              <CardBody>
                <Row body className="text-center">
                  <Col className="ml-auto mr-auto" md="12">
                    <CardTitle className="h3">
                      Bay Writes offers academic and creative writing
                      instruction in a supportive, fun, and professionally-led
                      setting.
                    </CardTitle>
                    <hr className="my-5" />
                  </Col>
                  <Col className="ml-auto mr-auto" md="12">
                    <div className="text-left">
                      <p className="lead">We Offer:</p>
                      <br />
                      <CardText>
                        <ul>
                          <li>
                            <h5>
                              Expert-led, small-group courses with
                              individualized support based on student needs and
                              goals
                            </h5>
                          </li>
                          <li>
                            <h5>
                              Emphasis on development of craft and mechanics
                            </h5>
                          </li>
                          <li>
                            <h5>
                              Support for each writer in developing their unique
                              voice
                            </h5>
                          </li>
                        </ul>
                      </CardText>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end flex-column" md="12">
                    <div className="text-left">
                      <p>
                        *At the present time, all instruction is provided online
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Container>
              <Row body className="text-left container-fluid">
                <Col className="ml-auto mr-auto" md="12">
                  <CardTitle className="h3">Testimonials</CardTitle>
                </Col>
                <Col className="row" md="12">
                  <Card className="card-plain col-sm-4">
                    <CardBody>
                      <CardText>
                        <p class="font-italic">
                          I loved this class. Not only did I learn how to write
                          better, I learned about different types of writing
                          jobs and things to consider in a writing career. I had
                          no idea I could learn so much in a week!
                        </p>
                        <br />
                        <p class="text-right font-weight-light ml-2">
                          Astrid, sophomore, San Francisco
                        </p>
                      </CardText>
                    </CardBody>
                  </Card>
                  <Card className="card-plain col-sm-4">
                    <CardBody>
                      <CardText>
                        <p class="font-italic">
                          This class was so fun and very informative! Our
                          teacher did a great job of presenting the information
                          in a way that is both engaging and inspiring. This
                          class has definitely gotten me more interested in
                          blogs, articles, and the many roles that go into
                          writing.
                        </p>
                        <br />
                        <p class="text-right font-weight-light ml-2">
                          Olivia, freshman, San Francisco
                        </p>
                      </CardText>
                    </CardBody>
                  </Card>
                  <Card className="card-plain col-sm-4">
                    <CardBody>
                      <CardText>
                        <p class="font-italic">
                          I love how I could create my own blog to share all of
                          my thoughts and ideas with the world. It was so fun
                          and now I am way more interested in internet writing.
                        </p>
                        <br />
                        <p class="text-right font-weight-light ml-2">
                          Eloise, 8th grade, San Francisco
                        </p>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <h5 className="description"> </h5>
          </Container>
        </div>
        <div className="section section-tinted text-center">
          <Container>
            <Row body className="text-center">
              <Col className="ml-auto mr-auto" md="6">
                <h2 className="text-dark">
                  <strong>Upcoming Courses</strong>
                </h2>
                <Card className="card-register align-items-center text-left">
                  <CardTitle>Write A Powerful Personal Essay</CardTitle>
                  <br />
                  <CardText>
                    <h4>September 1, 8, 15, 22 (Tuesday meetings)</h4>
                    <br />
                    <h5 className=" text-dark text-center">
                      Learn how to write about transformational experiences in a
                      way that makes your reader sit up and pay attention.
                    </h5>
                  </CardText>
                  <Button
                    className="btn-square"
                    color="primary"
                    to="/courses#essay"
                    tag={Link}
                  >
                    More Info / Sign Up
                  </Button>
                </Card>
                <br />
                <Card className="card-register align-items-center">
                  <CardTitle>Creative Writer Workshop</CardTitle>
                  <br />
                  <CardText>
                    <h4>September 3, 10, 17, 24 (Thursday meetings)</h4>
                    <br />
                    <h5 className="description text-dark text-center">
                      Get inspired, write and share with a supportive community
                      across genres: poetry, fiction, and memoir.
                    </h5>
                  </CardText>
                  <Button
                    className="btn-square"
                    color="primary"
                    to="/courses#creative"
                    tag={Link}
                  >
                    More Info / Sign Up
                  </Button>
                </Card>
                <br />
                <Card className="card-register align-items-center">
                  <CardTitle>
                    Weekend Workshop: Write a Powerful Personal Statement
                  </CardTitle>
                  <br />
                  <CardText>
                    <h4>September 19-20 and October 3-4</h4>
                    <br />
                    <h5 className="description text-dark text-center">
                      Want to make an admissions committee sit up and pay
                      attention? In this weekend course, you’ll get guided,
                      step-by-step instruction to writing a compelling personal
                      statement--one that truly showcases you.
                    </h5>
                  </CardText>
                  <Button
                    className="btn-square"
                    color="primary"
                    to="/courses#statement"
                    tag={Link}
                  >
                    More Info / Sign Up
                  </Button>
                </Card>
                <br />
                <Card className="card-register align-items-center">
                  <CardTitle>
                    Write for the Internet and Get Paid to Do It
                  </CardTitle>
                  <br />
                  <CardText>
                    <h4>October 6, 13, 20, 27</h4>
                    <br />
                    <h5 className="description text-dark text-center">
                      Love music? Cooking? Sports? Photography? TikTok dance
                      challenges? Whatever your passion is, you can write about
                      it online and get paid to do it.
                    </h5>
                  </CardText>
                  <Button
                    className="btn-square"
                    color="primary"
                    to="/courses#internet"
                    tag={Link}
                  >
                    More Info / Sign Up
                  </Button>
                </Card>
                <br />
                <Card className="card-register align-items-center">
                  <CardTitle>Individual Coaching & Support</CardTitle>
                  <br />
                  <CardText>
                    <h4>Ongoing - inquire for availability</h4>
                    <br />
                    <h5 className="description text-dark text-center">
                      Working on a blog, a story, or an essay? Or are you
                      looking for some academic and creative writing support?
                      Whatever your writing needs, we’re here to help. Our
                      instructors are available for 1 hour coaching and support
                      sessions with flexible scheduling.
                    </h5>
                  </CardText>
                  <Button
                    className="btn-square"
                    color="primary"
                    to="/courses#coaching"
                    tag={Link}
                  >
                    More Info / Sign Up
                  </Button>
                </Card>
                <br />
                <br />
                <br />
                <Button
                  className="btn-square"
                  color="link"
                  to="/courses"
                  tag={Link}
                >
                  View All Courses
                </Button>
              </Col>
            </Row>
            <br />
            <br />
          </Container>
        </div>
        {/* <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Meet the Instructors</h2>
            <Row>
              <Col md="4">
                <Card className="card-profile">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Katie Hunter</CardTitle>
                        <h6 className="card-category">Lead Instructor</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Katie is a writer and teacher.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    {/* <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button> */}
        {/* </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/joe-gardner-2.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Otis</CardTitle>
                        <h6 className="card-category">Guest Speaker</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Otis is a writer at the SF Chronicle.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button> */}
        {/* <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button> */}
        {/* </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/erik-lucatero-2.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Natalie</CardTitle>
                        <h6 className="card-category">Guest Speaker</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Natlie takes photos and stuff.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-instagram" />
                    </Button> */}
        {/* <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>*/}
        {/* </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div> */}
        <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto mt-4" md="8">
                <h4 className="text-center m-4">
                  Interested in enrolling in a course, or have a question? Let
                  us know how we can help!
                </h4>
                <ContactForm className="container text-black"></ContactForm>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Index;
