import React, { Component } from "react";
import {
  // Alert,
  Button,
  Col,
  Form,
  // InputGroup,
  // InputGroupText,
  // InputGroupAddon,
  Input,
  Row,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";

var options = {
  place: "bc",
  message: "",
  type: "info",
  icon: "now-ui-icons ui-1_bell-53",
  autoDismiss: 10,
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
  }

  /* Notification handler */
  notifySuccess() {
    options.type = "info";
    options.message = (
      <div>
        <div className="h5">
          Your inquiry has been submitted! <br />
          Expect a response from Katie soon, or email her at katie@baywrites.com
          if you do not receive a reply in a couple of days.
        </div>
      </div>
    );
    this.refs.notify.notificationAlert(options);
  }
  notifyError() {
    options.type = "danger";
    options.message = (
      <div>
        <div>
          There was an error upon submission. <br />
          Please email Katie at katie@baywrites.com
        </div>
      </div>
    );
    this.refs.notify.notificationAlert(options);
  }
  /* function for the form submission */

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "bay-writes-contact-form", ...this.state }),
    })
      .then(() => this.notifySuccess())
      .catch((error) => this.notifyError());

    e.preventDefault();
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, message } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md="6">
            <label className="h6">Your Name</label>
            <Input
              placeholder="Name"
              type="text"
              name="name"
              value={name}
              onChange={this.handleChange}
            />
          </Col>
          <Col md="6">
            <label className="h6">Your Email</label>
            <Input
              placeholder="Your Email"
              type="text"
              name="email"
              value={email}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <label className="h6 mt-4">Message</label>
        {/* <Input name="course" type="hidden" value={this.props.courseLabel} /> */}
        <Input
          placeholder="Have a question or interested in enrolling in a class?"
          type="textarea"
          rows="4"
          name="message"
          onChange={this.handleChange}
          value={message}
        />
        <Button
          className="btn-fill float-right mt-4"
          color="info"
          size="lg"
          type="submit"
        >
          Send Message
        </Button>
        <NotificationAlert ref="notify" className="zindex-tooltip" />
      </Form>
    );
  }
}

export default ContactForm;
