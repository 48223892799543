/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import NavbarHome from "components/Navbars/NavbarHome.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Footer from "components/Footers/Footer.js";

function About() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <NavbarHome />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/katie.jpg")}
              />
            </div>
            {/* <div className="name">
              <h4 className="title">
                About Bay Writes <br />
              </h4>
            </div> */}
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="10">
              <h2 className="description">About Bay Writes</h2>
              <br />
              <h5>
                Bay Writes offers academic and creative writing instruction in a
                supportive, fun, and professionally-led setting. All of our
                courses are designed and taught by credentialed educators and
                published authors with a passion for their craft, ranging from
                poetry to memoir to travel blogging. Each course is also limited
                to 6 students (or, in the case of our weekend workshops, to four
                students), allowing our instructors to offer plenty of
                individual support and coaching.
              </h5>
              <br />
              <h3 className="description">Course Design</h3>
              <br />
              <h5>
                We design our courses to spark creativity, and to offer tools
                and techniques to help each writer channel their unique voice on
                the page.
              </h5>
              <h5>
                We also emphasize college and career readiness, so that students
                leave ready to use their writing skills in practical and
                critical arenas.
              </h5>
              <h5>
                Most of all, our courses are built to inspire students and
                ignite their passion for writing. Through creative processes,
                workshops, and readings by diverse, contemporary authors, we
                help students explore the wide, wonderful world of creative
                writing and find their place within it.
              </h5>
              <h5>
                Think of us as a junior MFA program and your student’s favorite
                coach rolled into one.
              </h5>
              <br />
            </Col>
            <Col className="ml-auto mr-auto text-left" md="10">
              <h3 className="description">About Katie</h3>
              <br />
              <h5>
                Katie Hunter is the founder and main instructor of bay writes.
                Read more about Katie and her experience at{" "}
                <a
                  href="https://www.katiehunterwriter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  katiehunterwriter.com
                </a>{" "}
              </h5>
              <br />
            </Col>
          </Row>
          <br />
          {/* <div className="nav-tabs-navigation">
            <h3 className="description">Past Guest Instructors</h3>
            <br />
          </div> */}
          {/* Tab panes */}
          {/* <TabContent className="following">
            <TabPane tabId="1" id="follows">
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <ul className="list-unstyled follows">
                    <li>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={require("assets/img/faces/clem-onojeghuo-2.jpg")}
                          />
                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="4" xs="4">
                          <h6>
                            Natalie <br />
                            <small>Travel Blogger & Photographer</small>
                          </h6>
                        </Col>
                      </Row>
                    </li>
                    <hr />
                    <li>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={require("assets/img/faces/ayo-ogunseinde-2.jpg")}
                          />
                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="4" xs="4">
                          <h6>
                            Otis R. Taylor Jr.
                            <br />
                            <small>Columnist - San Francisco Chronicle</small>
                          </h6>
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </Col>
              </Row>
            </TabPane>
          </TabContent> */}
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default About;
