/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import NavbarHome from "components/Navbars/NavbarHome.js";
import Footer from "components/Footers/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import ContactForm from "components/ContactForm.js";

function Inquiry() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <NavbarHome />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto mt-4" md="8">
              <h3 className="text-center m-4">
                Questions, or interested in signing up for a course or coaching?
                Reach out to Katie in the form below, or email her at{" "}
                <a href="mailto:katie@baywrites.com">katie@baywrites.com</a>.
              </h3>
              <br />
              <ContactForm></ContactForm>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Inquiry;
